import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import ImageModal from '../ImageModal/ImageModal';
import './ProductCard.css';

interface ProductCardProps {
  idProduct: number;
  nameProduct: string;
  code: string;
  price: number;
  imgUrl: string;
  available: boolean;
  isOnDemand: boolean;
  lowest: number;
  multiple: number;
  isEnabled: string; 
}

const ProductCard: React.FC<ProductCardProps> = ({
  idProduct,
  nameProduct,
  code,
  price,
  imgUrl,
  available,
  isOnDemand,
  lowest,
  multiple,
  isEnabled
}) => {
  const {
    getItemQuantity,
    increaseCartQuantity,
    decreaseCartQuantity,
    removeFromCart,
  } = useShoppingCart();
  const quantity = getItemQuantity(code);
  const toast = useRef<Toast>(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false); 
  const { t } = useTranslation();

  const save = () => {
    increaseCartQuantity(code, nameProduct, price, imgUrl, lowest, multiple);
    toast.current?.show({
      severity: 'success',
      summary: '¡Correcto!',
      detail: 'Elemento añadido correctamente.',
    });
  };

  const handleImageClick = () => {
    setIsImageModalOpen(true); 
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false); 
  };

  return (
    <>
      <Card
        style={{
          width: '13rem',
          height: '350px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <div
          style={{
            maxHeight: '150px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={handleImageClick}
        >
          <Card.Img
            src={imgUrl}
            style={{ height: '100%', objectFit: 'cover' }}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = 'https://res.cloudinary.com/dkfvgi7bh/image/upload/v1717055104/nfgnbce4ub9k6tftpyfy.jpg';
            }}
          />
        </div>
        <Card.Body style={{ textAlign: 'center' }}>
          <Card.Title className="code-product">{code}</Card.Title>
          <Card.Title className="name-product">
            <strong>{nameProduct.toLocaleLowerCase()}</strong>
          </Card.Title>
          <Card.Text className="code-product">{`${price.toFixed(2)} €`}</Card.Text>
          {available ? (
            <Tag
              className="bg-green-100"
              color="green"
              icon="pi pi-check"
              severity="warning"
              value={t('card_component.tag.available')}
            ></Tag>
          ) : (
            <Tag
              className="bg-red-100"
              icon="pi pi-times"
              severity="warning"
              value={t('card_component.tag.unavailable')}
            ></Tag>
          )}
          {isOnDemand ? (
            <Tag
              className="bg-orange-100"
              icon="pi pi-exclamation-circle"
              severity="warning"
              data-pr-tooltip={t('card_component.tag.on_demand')}
              value={t('card_component.tag.on_demand')}
            ></Tag>
          ) : null}
          {lowest > 1 ? (
            <Tag
              className="bg-orange-100"
              icon="pi pi-exclamation-circle"
              severity="warning"
              data-pr-tooltip={`${t('card_component.tag.sold_in_packs')} ${lowest}`}
              value={`${t('card_component.tag.sold_in_packs')} ${lowest}`}
            ></Tag>
          ) : null}
          {isEnabled === "Agotar" ? (
          <Tag
            className="bg-blue-100"
            icon="pi pi-tag"
            severity="warning"
            value={t('card_component.tag.end_stock')}
          ></Tag>
        ) : null}
          <button
            className="meso-button-card"
            onClick={save}
          >
            {t('card_component.add_to_cart_btn')}
          </button>
        </Card.Body>
      </Card>

      {isImageModalOpen && <ImageModal imgUrl={imgUrl} onClose={handleCloseImageModal} />}
    </>
  );
};

export default ProductCard;
